import { addActiveLinkClass, scrollToTop } from '../../utils';

document.addEventListener('DOMContentLoaded', () => {
  addActiveLinkClass({
    anchorSelector: '.main-footer__row a',
  });

  const toTopBtn = document.querySelector('.to-top');
  if (toTopBtn) {
    toTopBtn.addEventListener('click', () => scrollToTop());

    if (window.visualViewport.width > 1180) {
      const footerNode = document.querySelector('.main-footer');

      document.addEventListener('scroll', () => {
        if (window.visualViewport.height > window.scrollY) {
          toTopBtn.style.display = 'none';
        } else if (footerNode.offsetTop - footerNode.clientHeight + 380 < window.scrollY) {
          toTopBtn.style.display = 'flex';
          toTopBtn.classList.add('to-top__reached-footer');
        } else {
          toTopBtn.style.display = 'flex';
          toTopBtn.classList.remove('to-top__reached-footer');
        }

        document.removeEventListener('scroll', document);
      });
    }
  }
});
