// import getFormattedDate from '../../../utils/getFormattedDate';

const template = i => `<div class="splide__slide testimonials__item">
  <div class="testimonials__item__customer">
    <div class="testimonials__item__customer__id">ID ${i.customerId}</div>
  </div>
  <div class="testimonials__item__text">${i.body}</div>
</div>`;

export default template;
