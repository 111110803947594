/* eslint-disable */
import exitPopup from './template';
import './style.scss';

function addEvent(obj, evt, fn) {
  if (obj.addEventListener) {
    obj.addEventListener(evt, fn, false);
  } else if (obj.attachEvent) {
    obj.attachEvent(`on${evt}`, fn);
  }
}

const isTablet = window.matchMedia('(max-width: 1024px)').matches;
const getCookie = (name) => {
  const matches = document.cookie.match(
    new RegExp(
      `(?:^|; )${`${name}`.replace(
        /([\.$?*|{}\(\)\[\]\\\/\+^])/g,
        '\\$1',
      )}=([^;]*)` /* eslint-disable-line */,
    ),
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

class ExitPopup {
  constructor({
    targetPage = null,
    storage = 'localStorage',
    hideForExistsUsers = false,
    random = false,
    template = exitPopup,
    gaEventName = 'ExitPopup',
    gaEventLabel = null,
    bannerName = null,
    container = null,
    analytics = {
      type: 'gtag',
    },
  }) {
    this.targetPage = targetPage;
    this.gaEventLabel = gaEventLabel;
    this.containerPopup = document.createElement('div');
    this.storage = storage;
    this.hideForExistsUsers = hideForExistsUsers;
    this.random = random;
    this.template = template;
    this.content = document.createElement('div');
    this.overlay = document.createElement('div');
    this.gaEventName = gaEventName;
    this.container = container ? document.querySelector(container) : document.documentElement;
    this.bannerName = bannerName;
    this.analytics = { enable: true, ...analytics };
  }

  init() {
    if (!this.bannerName && this.bannerName === '') throw new Error('bannerName is empty!');

    const lastAuthorizedAt = this.hideForExistsUsers
      ? localStorage.getItem('lastAuthorizedAt') !== null
      : false;
    const acceptToShow = !this.checkInStorage() && !lastAuthorizedAt;

    if (acceptToShow) {
      this.containerPopup.classList.add('ExitPopup');
      this.containerPopup.classList.add(this.bannerName);
      this.containerPopup.classList.add('ExitPopup--hidden');
      this.content.classList.add('ExitPopup__content');
      this.overlay.classList.add('ExitPopup__overlay');

      if (this.random && Array.isArray(this.template)) {
        const randIndx = Math.floor(Math.random() * (this.template.length - 1 + 1));

        const template = document.createElement('div');
        template.innerHTML = this.template[randIndx].trim();
        const gaTemplateLabel = template.querySelector('[data-banner-btn]');

        if (gaTemplateLabel) {
          this.gaEventLabel = gaTemplateLabel.dataset.gaLabel || null;
        }

        this.content.innerHTML = this.template[randIndx];
      } else {
        this.content.innerHTML = Array.isArray(this.template) ? this.template[0] : this.template;
        this.gaEventLabel = this.gaEventLabel || null;
      }

      this.containerPopup.appendChild(this.content);
      this.containerPopup.appendChild(this.overlay);
      this.container.appendChild(this.containerPopup);

      setTimeout(() => {
        this.open();
      }, 5000);

      const closeNode = this.container.querySelector('.btn-close');
      if (closeNode) {
        closeNode.addEventListener('click', () => {
          this.close();
        });
      }

      this.overlay.addEventListener('click', () => {
        this.close();
      });

      const buttonNode = this.container.querySelector('[data-banner-btn]');
      if (buttonNode) {
        buttonNode.addEventListener('click', () => {
          this.close();
          this.sendEvent('PopupBanner', 'useCoupon');
        });
      }
    }
  }

  checkInStorage = () =>
    this.storage === 'cookie'
      ? getCookie(`${this.bannerName}_popup_closed`) !== undefined
      : localStorage.getItem(`${this.bannerName}_popup_closed`);

  sendEvent(event, action) {
    dataLayer.push({
      event: event,
      action: action,
    });
  }

  close() {
    this.containerPopup.classList.remove('ExitPopup--visible');
    this.containerPopup.classList.add('ExitPopup--hidden');
    if (this.storage === 'cookie') {
      document.cookie = `${this.bannerName}_popup_closed=${new Date().getTime()}`;
    } else {
      localStorage.setItem(`${this.bannerName}_popup_closed`, '1');
    }
    this.sendEvent('PopupBanner', 'close');
  }

  open() {
    if (!this.checkInStorage()) {
      this.containerPopup.classList.remove('ExitPopup--hidden');
      this.containerPopup.classList.add('ExitPopup--visible');
      this.sendEvent('PopupBanner', 'show');
    }
  }
}

export default ExitPopup;
