import { addActiveLinkClass } from '../../utils';

document.addEventListener('DOMContentLoaded', () => {
  const header = document.querySelector('.main-header');
  const headerSticky = document.querySelector('.main-header--position-sticky');
  const mobileMenuBtn = document.querySelector('.header-menu__mobile-menu-btn');
  const headerMenu = document.querySelector('.header-menu');

  if (!header && !mobileMenuBtn && !headerMenu) return;

  if (headerSticky) {
    const observer = new IntersectionObserver(
      ([e]) => e.target.classList.toggle('main-header--sticky', e.intersectionRatio < 1),
      { threshold: [1], rootMargin: '-1px 0px 0px 0px' },
    );
    observer.observe(headerSticky);
  }

  mobileMenuBtn.addEventListener('click', () => {
    mobileMenuBtn.classList.toggle('header-menu__mobile-menu-btn--active');
    headerMenu.classList.toggle('header-menu--open');
    header.classList.toggle('main-header--menu--open');
    document.body.classList.toggle('overflow');
    document.documentElement.classList.toggle('overflow');
  });

  addActiveLinkClass({
    anchorSelector: '.header-menu__list__item a',
  });
});
