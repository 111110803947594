import { observeElement } from '@uvocore-front/utils';
import tabsInit from '../Tabs';
import './style.scss';

observeElement({
  element: '.testimonials-tabs',
  marginValue: 400,
  callback: () => {
    const tab = document.querySelector('.testimonials-tabs');
    if (tab) {
      tabsInit({ selector: tab });
    }
    if (window.innerWidth <= 1024) {
      const select = document.querySelector('.Tabs-testimonials__select select');
      const tabsContent = document.querySelectorAll('[data-tab-content]');
      if (select) {
        select.addEventListener('change', e => {
          tabsContent.forEach(tabContent => {
            tabContent.classList.remove('is-active');
            if (e.target.value === tabContent.dataset.tabContent) {
              tabContent.classList.add('is-active');
            }
          });
        });
      }
    }
  },
});
