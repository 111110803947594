import '../polyfills';
import '../components/ImagesLazyLoading';
import '../components/NotifyWidget/index';
import ExitPopup from '../components/ExitPopup';

if (document.querySelector('[data-notify-widget]') && !document.getElementById('CommonPage')) {
  const popup = new ExitPopup({
    container: '[data-notify-widget]',
    random: false,
    storage: 'cookie',
    gaEventLabel: 'Special Deal on Free Inquiry',
    bannerName: 'exit-popup',
    hideForExistsUsers: false,
  });
  popup.init();
}
