import { observeElement } from '@uvocore-front/utils';
import tabsInit from '../Tabs';
import './style.scss';

observeElement({
  element: '.get-help-tabs',
  marginValue: 100,
  callback: () => {
    if (window.visualViewport.width > 1180) {
      const tab = document.querySelector('.get-help-tabs');
      if (tab) {
        tabsInit({ selector: tab });
      }
    }
  },
});
