import Slider from '../../Slider';

const TestimonialsSlider = container => {
  Slider(container, {
    loop: false,
    type: 'slider',
    mediaQuery: 'min',
    gap: '32px',
    arrows: true,
    pagination: true,
    breakpoints: {
      1024: {
        perPage: 3,
      },
      768: {
        perPage: 2,
      },
      640: {
        perPage: 1,
      },
    },
  }).mount();
};

export default TestimonialsSlider;
