import { observeElement } from '@uvocore-front/utils';

import SimpleBar from 'simplebar';
import '../../theme/DefaultTheme';
import '../../components/Header/index';
import '../../components/GetHelp/index';
import '../../components/TestimonialsTabs/index';
import '../../components/Calculator/default';
import '../../components/Calculator/short';
import '../../components/Footer';
import '../../components/Testimonials';

import Slider from '../../components/Slider/index';

observeElement({
  element: '[data-writers]',
  marginValue: 300,
  callback: () => {
    Slider('[data-writers]', {
      focus: 'center',
      type: 'slider',
      mediaQuery: 'min',
      gap: '36px',
      arrows: true,
      pagination: true,
      breakpoints: {
        1024: {
          destroy: true,
        },
        768: {
          perPage: 2,
        },
        640: {
          perPage: 1,
        },
      },
    }).mount();

    if (window.matchMedia('(max-width: 1024px)').matches) {
      [...document.querySelectorAll('.writers-slider__author')].forEach(el => {
        el.addEventListener('click', () => {
          el.classList.toggle('selected');
        });
      });
    }
  },
});

// Seo Text Horizontal Scroll
observeElement({
  element: '.section-seotext-horizontal__inner',
  marginValue: 300,
  callback: selector => {
    // eslint-disable-next-line no-new
    new SimpleBar(selector, {
      autoHide: false,
    });
  },
});
