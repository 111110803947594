import { observeElement, getWidget } from '@uvocore-front/utils';
import './Calculator.scss';

/**
 *  Calculator
 */
const isPageConfig =
  window.pageConfig &&
  Object.keys(window.pageConfig).length > 0 &&
  window.pageConfig.constructor === Object;

// Calculator Observer
if (document.querySelector('[data-calc-mini]')) {
  observeElement({
    element: '[data-calc-mini]',
    marginValue: 400,
    callback: () => {
      getWidget({
        widgetLink: '/assets/js/calc.js',
        fn: () => {
          const pagePaperType =
            isPageConfig &&
            window.pageConfig.calculator !== undefined &&
            // eslint-disable-next-line no-prototype-builtins
            window.pageConfig.calculator.hasOwnProperty('pagePaperType')
              ? window.pageConfig.calculator.pagePaperType
              : undefined;

          const calcOptions = {
            ID: '53',
            calculatorType: 'DefaultCalculator',
            format: 'json',
            options: {
              deafault: {
                pagesDefault: 1,
              },
            },
          };

          if (pagePaperType !== undefined) {
            calcOptions.options.deafault.paperTypesDefault = pagePaperType;
          }

          if (window.Calc) {
            const calc = new Calc(calcOptions); /* eslint-disable-line */
          }
        },
      });
    },
  });
}
