(function(window, document) {/*eslint-disable-line */
  function init() {
    window.NotifyWidget({
      siteId: '53',
      container: '.NotifyWidget-banner',
    });
  }

  if (typeof window.NotifyWidget !== 'undefined') {
    init();
  } else {
    const script = document.createElement('script');
    script.src = '/assets/js/notifyWidget.min.js';
    script.async = true;
    script.onload = () => {
      init();
    };
    document.getElementsByTagName('head')[0].appendChild(script);
  }
})(window, document);
