/**
 *
 * Using tabsInit
 *
 * observeElement({
 *   element: '[data-tabs]',
 *   marginValue: 400,
 *   callback: () => {
 *     const tabs = document.querySelectorAll('[data-tabs]');
 *     tabs.forEach((tab) => {
 *       tabsInit({ selector: tab });
 *     });
 *   },
 * });
 */

export default function tabsInit({ selector = '', startPaneIndex = 0 }) {
  const container = selector || document;
  const tabNavNodes = container.querySelectorAll('[data-tab-name]:not(.inactive-tab)');
  const tabContentNodes = container.querySelectorAll('[data-tab-content]');

  if (!tabContentNodes && !tabNavNodes) return;

  const selectTabContent = tab => {
    tabContentNodes.forEach(item => {
      const {
        dataset: { tabContent },
        classList,
      } = item;

      if (tab === tabContent) {
        classList.add('is-active');
      } else {
        classList.remove('is-active');
      }
    });
  };

  const selectTabNav = tab => {
    tabNavNodes.forEach(item => item.classList.remove('is-active'));
    tab.classList.add('is-active');
    selectTabContent(tab.dataset.tabName);
  };

  if (tabNavNodes[startPaneIndex]) {
    tabNavNodes[startPaneIndex].classList.add('is-active');
    selectTabContent(tabContentNodes[startPaneIndex].dataset.tabContent);
  }

  // eslint-disable-next-line consistent-return
  return new Promise(resolve => {
    tabNavNodes.forEach((item, index) =>
      item.addEventListener('click', () => selectTabNav(tabNavNodes[index])),
    );
    resolve(true);
  });
}
