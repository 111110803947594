import { observeElement, getWidget } from '@uvocore-front/utils';
import TestimonialsSlider from './TestimonialsSlider';
import template from './TestimonialTemplate';

if (document.querySelector('[data-all-testimonials]')) {
  observeElement({
    /* eslint-disable-line */
    element: '[data-all-testimonials]',
    marginValue: 500,
    callback: () => {
      getWidget({
        /* eslint-disable-line */
        widgetLink: '/assets/js/testimonialsWidget.min.js',
        loader: false,
        fn: () => {
          window.tmWidget({
            params: {
              siteId: '53',
              limit: 9,
              maxBodyLength: 100,
              minBodyLength: 60,
            },
            callbackData: responseData => {
              const { testimonials } = responseData;
              const testimonialsNode = document.querySelector('.testimonials');
              testimonialsNode.removeChild(testimonialsNode.querySelector('.Loader'));
              const tmNodes = testimonials
                .map(tm => {
                  return template(tm);
                })
                .join('');
              document
                .querySelector('[data-testimonials-widget-js]')
                .insertAdjacentHTML('afterbegin', tmNodes);

              TestimonialsSlider('[data-testimonials]');
            },
            loader: false,
            containerEl: '[data-testimonials-widget-js]',
          });
        },
      });

      TestimonialsSlider('[data-testimonials-omnipapers]');
    },
  });
}
